








































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PDFConstructorReadOnly from "./../../pdf/PDFConstructorReadOnly.vue";
import PDFConstructor from "./../../pdf/PDFConstructor.vue";
import PDFUserDataHelper from "../../pdf/PDFUserDataHelper.vue";
import PDFMetrics from "../../pdf/PDFMetrics.vue";
import _ from "lodash";

const sleep = m => new Promise(r => setTimeout(r, m))

@Component({ components: { PDFConstructorReadOnly, PDFConstructor, PDFUserDataHelper, PDFMetrics } })
export default class PDFConstructorReadOnlyPage extends Vue {

  disclosures: any = [];
  disclosure: any = {};
  selectedDisclosure: any = null;
  loanTxnId: string = null;
  loanTxnIdInput: string = null;
  userData: any = null;
  formId: any = null;

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  receiveUserData(userData) {
    this.userData = userData;
  }

  onKeySelected(key) {
    this.$refs.pdfconstructor['addKeyToSelectedField'](key);
  }

  async previousForm() {
    await this.selectDisclosure(this.disclosures[this.$refs.selectDisclosure['selectedIndex'] - 2].formId);
    this.$refs.selectDisclosure['selectedIndex']--;
  }

  async nextForm() {
    await this.selectDisclosure(this.disclosures[this.$refs.selectDisclosure['selectedIndex']++].formId);
  }

  async selectDisclosure(event) {
    this.selectedDisclosure = null;
    
    this.formId = _.get(event, 'target.value', event);

    await sleep(500);
    if(_.get(this.$route.query, 'formId' , '').toString() != this.formId) {
      this.$router.replace({query: {formId: `${this.formId}`, loanTxnId: this.loanTxnId }});
    }
    this.selectedDisclosure = this.disclosures.find(disclosure => disclosure.formId == this.formId);
  }

  async getDisclosures() {

    const response = await Axios.post(
      BASE_API_URL + "disclosure/getDisclosuresConstants"
    );

    this.disclosures = response.data.disclosures;
  }

  async goToLoan() {
    this.loanTxnId = null;
    this.$router.push({ query: {formId: `${this.formId}`, loanTxnId: this.loanTxnIdInput }});
    this.loanTxnId = this.loanTxnIdInput;

    await this.initPDFConstructor()
  }

  async initPDFConstructor() {
    await this.getDisclosures();

    this.$refs.selectDisclosure['selectedIndex'] = 
      this.disclosures.findIndex(
        disclosure => 
          disclosure.formId == _.get(this.$route.query, 'formId' , '').toString()
          ) + 1;

    this.loanTxnId = _.get(this.$route.query, 'loanTxnId' , '').toString();

    if (!this.loanTxnId) {
      await this.getLoanTxnId();
    }

    const URIQuery = { loanTxnId: this.loanTxnId };
    const formId = _.get(this.$route.query, 'formId' , '').toString();

    if (formId) {
      URIQuery['formId'] = formId;
    }

    this.$router.replace({ query: URIQuery });
    this.loanTxnIdInput = this.loanTxnId; 

    this.selectDisclosure(_.get(this.$route.query, 'formId' , '').toString());
  }

  async getLoanTxnId() {
    const { data: { borrowers: [{ loanTxnId }] } } = await Axios.post(
      BASE_API_URL + "superAdmin/getBorrowers",
      {
        userId: this.$userId,
        type: this.$userType,
        options: {
          skip: 0,
          limit: 1,
        },
      }
    );

    if (loanTxnId) {
      this.loanTxnId = loanTxnId;
    }

  }

  async mounted() {
    this.initPDFConstructor();
  }

  async updateDisclosures(disclosure) {
    this.disclosures[this.disclosures.findIndex(form => form.formId == disclosure.formId)] = disclosure;
  }

  async validateDisclosure(disclosure, saved) {
    if(saved) {
      this.disclosure = disclosure;
      this.disclosure.validate = true;
    }
  }
}
